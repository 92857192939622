html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #FBFBFB;
  /*overflow: hidden;*/
}

#root {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex:1;
  background-color: #FBFBFB;
}

.App-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Prend 100% de la hauteur du parent (.App-keyboard) */
  flex:1;
}

.animated-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Prend 100% de la hauteur du parent (.App-keyboard) */
  flex:1;
}


